<template>
  <div>
    <CCard>
      <CCardHeader>
        {{ $t('Lazy table') }}
      </CCardHeader>
      <CCardBody :class="{skeleton: !sampleLazyTable}">
        <table-component
          v-if="sampleLazyTable"
          :url="lazyTableUrl"
          :lazy-table="sampleLazyTable"
          displayButtonsRow
        >
          <template #Peak="{ row }">
            <div data-cy="row-peak">{{ row.Peak }}</div>
          </template>
          <template #Ascents="{ row }">
            <div data-cy="row-ascents">{{ row.Ascents }}</div>
          </template>
        </table-component>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { SampleService } from '@/services/sample';
import { API_ENDPOINTS } from '@/constants/common';

export default {
  name: 'LazyTable',
  data() {
    return {
      lazyTableUrl: API_ENDPOINTS.lazyTable,
      sampleLazyTable: null,
    };
  },
  mounted() {
    this.fetchLazyTable();
  },
  methods: {
    async fetchLazyTable() {
      this.sampleLazyTable = await SampleService.fetchLazyTable();
    },
  },
};
</script>
